:root {
  --beige: #efc081;
  --blue: #487191;
  --bordeaux: #582a2a;
  --green: #526660;
}

@font-face {
  font-family: "arnopro-regular";
  src: local("arnopro-regular"),
    url(./assets/fonts/arnopro-regular.otf) format("truetype");
  font-display: swap;
}

html {
  overscroll-behavior-x: contain;
}

body {
  overscroll-behavior-y: contain;
  overscroll-behavior-x: contain;
  /* -ms-touch-action: none; */
  margin: 0;
  background-color: var(--bordeaux);
  -webkit-user-select: none; /* Disable selection in Webkit browsers */
  -moz-user-select: none; /* Disable selection in Mozilla Firefox */
  -ms-user-select: none; /* Disable selection in Microsoft Edge */
  user-select: none; /* Disable selection in standard browsers */
}

.ornament {
  width: 100px;
  margin: 30px 0;
}

.icon {
  width: 50px;
  height: 50px;
}

/* homepage */

.home-view,
.selector-view,
.playback-view {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titles {
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-title {
  font-family: "arnopro-regular";
  color: white;
  font-size: 40px;
  text-align: center;
  font-weight: 400;
}

.main-title:nth-of-type(2) {
  max-width: 200px;
}

/* audioselector page */

.selector-view .icon {
  align-self: flex-start;
  padding-left: 50px;
}

.titles-holder {
  max-width: 100%;
  padding: 20px;
  padding-left: 50px;
}

.titles-holder ul {
  list-style-type: none !important;
  padding: 0;
}

.titles-holder .title {
  font-family: "arnopro-regular";
  color: white;
  font-size: 30px;
  text-align: left;
  font-weight: 200;
  margin: 40px 0;
}

/* playback page */

.playback-view .titles-holder {
  max-width: 60%;
  padding-left: unset;
}

.playback-view .titles-holder .title {
  font-size: 40px;
  text-align: center;
  margin: 0;
}

.soundicon {
  width: 60px;
  height: 60px;
}

.timeline-outer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.timeline {
  width: 60%;
  -webkit-appearance: none;
  margin-top: 25px;
}

.timeline::-webkit-slider-runnable-track {
  height: 3px;
  background: #ffffff;
}

.timeline::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #ffffff;
  width: 12px;
  height: 12px;
  border-radius: 10px;
  margin-top: -5px;
  outline: 2px solid #ffffff;
  border: 1px solid var(--bordeaux);
}

input[type="range"]::-moz-range-thumb {
  background-color: #694e38;
  width: 10px;
  height: 10px;
}

.playback-view .icon {
  align-self: flex-start;
  margin-top: 70px;
  padding-left: 50px;
}
